import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './footnote.css';

const propTypes = {
  id: PropTypes.string.isRequired,
  bubbleId: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

class Footnote extends Component {
  handleClick = () => {
    const { id, bubbleId, onClick } = this.props;
    onClick(id, bubbleId);
  }

  render() {
    const { id, bubbleId, isActive, text } = this.props;

    return (
      <div className={classNames('Footnote', {
        'is-active': isActive
      })}>
        <a
          id={id}
          className="Footnote-link"
          href={`#${bubbleId}`}
          onClick={this.handleClick}
        >
          {text}
          &nbsp;
          {'<<'}
        </a>
      </div>
    );
  }
}

Footnote.propTypes = propTypes;

export default Footnote;
