import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Footnote from './footnote';
import footnotes from 'data/footnotes.json';
import './footnote-group.css';

const propTypes = {
  active: PropTypes.string,
  lang: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

class FootnoteGroup extends Component {
  render() {
    const { active, lang, onClick } = this.props;

    return (
      <div className="FootnoteGroup">
        {
          footnotes.map((footnote) => {
            return (
              <Footnote
                key={footnote.id}
                id={footnote.id}
                bubbleId={footnote.bubble}
                isActive={active === footnote.id}
                onClick={onClick}
                text={footnote.text[lang]}
              />
            );
          })
        }
      </div>
    );
  }
}

FootnoteGroup.propTypes = propTypes;

export default FootnoteGroup;
