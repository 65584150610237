import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './external-link.css';

import { CIRCLE_LINK_RADIUS } from 'config';

const propTypes = {
  className: PropTypes.string,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  href: PropTypes.string.isRequired,
  blank: PropTypes.bool.isRequired,
};

class ExternalLink extends Component {
  static defaultProps = {
    blank: false,
  }

  render() {
    const { className, x, y, href, blank } = this.props;

    return (
      <a
        className={classNames('ExternalLink', className)}
        href={href}
        xlinkHref={href}
        target={ blank ? '_blank' : '_self' }
      >
        <circle
          className="ExternalLink-circle"
          cx={x}
          cy={y}
          r={CIRCLE_LINK_RADIUS}
        />
      </a>
    );
  }
}

ExternalLink.propTypes = propTypes;

export default ExternalLink;
