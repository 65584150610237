import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './link.css';

import { CIRCLE_LINK_RADIUS } from 'config';

const propTypes = {
  className: PropTypes.string,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  href: PropTypes.string.isRequired,
};

class Link extends Component {
  render() {
    const { className, x, y, href } = this.props;

    return (
      <a
        className={classNames('Link', className)}
        href={href}>
        <circle
          className="Link-circle"
          cx={x}
          cy={y}
          r={CIRCLE_LINK_RADIUS}
        />
      </a>
    );
  }
}

Link.propTypes = propTypes;

export default Link;
