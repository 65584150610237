import getViewportCenter from './get-viewport-center';
import EasingFunctions from './easing-functions';
const easeInOut = EasingFunctions.easeInOutCubic;

const MS_PER_FRAME = 1000 / 60;

const defaultOptions = {
  duration: 1000,
  elementClassName: 'container',
  scrollToCenter: false,
};

function scrollElementTo(targetPositionParam, optionsParam) {
  const options = Object.assign({}, defaultOptions, optionsParam);
  const viewportCenter = getViewportCenter();
  const targetPosition = options.scrollToCenter ?
    [
      targetPositionParam[0] - viewportCenter.left,
      targetPositionParam[1] - viewportCenter.top,
    ] :
    targetPositionParam;

  const element = document.querySelector(`.${options.elementClassName}`);

  let isStopped = false;
  let currentPosition = [element.scrollLeft, element.scrollTop];

  // Calculate number of steps.
  const numSteps = Math.round(options.duration / MS_PER_FRAME) || 1;

  const startX = element.scrollLeft;
  const startY = element.scrollTop;

  const distanceX = targetPosition[0] - currentPosition[0];
  const distanceY = targetPosition[1] - currentPosition[1];

  let currentStep = 1;

  if (currentStep <= numSteps) {
    window.requestAnimationFrame(step);
  }

  return stopScroll;

  function normalizeInput(input) {
    return input / numSteps;
  }

  function denormalizeOutputX(output) {
    return startX + distanceX * output;
  }

  function denormalizeOutputY(output) {
    return startY + distanceY * output;
  }

  function step(timeStampSincePageLoad) {
    if (isStopped) {
      return;
    }

    currentPosition[0] = denormalizeOutputX(easeInOut(normalizeInput(currentStep)));
    currentPosition[1] = denormalizeOutputY(easeInOut(normalizeInput(currentStep)));

    element.scrollLeft = currentPosition[0];
    element.scrollTop = currentPosition[1];

    currentStep += 1;

    if (currentStep <= numSteps) {
      window.requestAnimationFrame(step);
    }
  }

  function stopScroll() {
    isStopped = true;
  }
}

export default scrollElementTo;
