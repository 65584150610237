import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const LANG_EN = 'en';
export const LANG_DE = 'de';
export const LANG_DEFAULT = LANG_EN;

const propTypes = {
  lang: PropTypes.string.isRequired,
  onLangChange: PropTypes.func.isRequired,
};

class LangSetting extends Component {
  handleEnClick = () => {
    this.props.onLangChange(LANG_EN);
  }

  handleDeClick = () => {
    this.props.onLangChange(LANG_DE);
  }

  render() {
    const { lang } = this.props;

    return (
      <div>
        <button
          type="button"
          className={classNames('Settings-button', 'Settings-button--lang', {
            'is-disabled': lang === LANG_EN,
          })}
          onClick={this.handleEnClick}
          disabled={lang === LANG_EN}
        >
          {LANG_EN}
        </button>
        {'/'}
        <button
          type="button"
          className={classNames('Settings-button', 'Settings-button--lang', {
            'is-disabled': lang === LANG_DE,
          })}
          onClick={this.handleDeClick}
          disabled={lang === LANG_DE}
        >
          {LANG_DE}
        </button>
      </div>
    );
  }
}

LangSetting.propTypes = propTypes;

export default LangSetting;
