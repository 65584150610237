import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './title.css';

// Approximate dimensions of title.
const TITLE_WIDTH = 875;
const TITLE_HEIGHT = 420;
const TITLE_X_POSITION = 48 * 16;
const TITLE_Y_POSITION = 48 * 16;

export const TITLE_CENTER = [
  TITLE_X_POSITION + TITLE_WIDTH / 2,
  TITLE_Y_POSITION + TITLE_HEIGHT / 2
];

const propTypes = {
  lang: PropTypes.string.isRequired,
};

class Title extends Component {
  render() {
    const { lang } = this.props;

    switch (lang) {
    case 'de':
      return (
        <header className="Title">
          <h1 className="Title-title">
            Abstracta
          </h1>
          <p className="Title-subtitle">
            Onlinearchiv zum politischen Charakter<br/>
            der frühen documenta Ausstellungen<br/>
            von Minna Henriksson
          </p>
          <p className="Title-instructions">
            Mit Klick auf eine der nierenförmigen Zeichnungen erscheint ein Text.<br/>
            Bei erneutem Klick auf diese Zeichnung gelangst Du zum nächsten Text.
          </p>
        </header>
      );
    case 'en':
    default:
    return (
      <header className="Title">
        <h1 className="Title-title">
          Abstracta
        </h1>
        <p className="Title-subtitle">
          Online Archive about the Political Nature<br/>
          of the Early documenta Exhibitions<br/>
          by Minna Henriksson
        </p>
        <p className="Title-instructions">
          By clicking a bubble in the drawing you can read a text.<br/>
          Click again in the drawing and you move to the next one.
        </p>
      </header>
    );
    }
  }
}

Title.propTypes = propTypes;

export default Title;
