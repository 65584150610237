function getFootnoteOffset(id) {
  const footnote = document.getElementById(id);

  let top = footnote.offsetTop || 0;
  let left = footnote.offsetLeft || 0;

  const footnoteGroup = footnote.offsetParent;
  top += footnoteGroup.offsetTop || 0;
  left += footnoteGroup.offsetLeft || 0;

  return {
    top,
    left,
  };
}

export default getFootnoteOffset;
