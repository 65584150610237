import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const ZOOM_FACTOR_SMALL = 0.5;
export const ZOOM_FACTOR_MEDIUM = 0.75;
export const ZOOM_FACTOR_LARGE = 1;
export const ZOOM_FACTOR_DEFAULT = ZOOM_FACTOR_SMALL;

const propTypes = {
  zoom: PropTypes.number.isRequired,
  onZoomChange: PropTypes.func.isRequired,
};

class ZoomSetting extends Component {
  handlePlusClick = () => {
    const { zoom, onZoomChange } = this.props;

    switch(zoom) {
    case ZOOM_FACTOR_SMALL:
      onZoomChange(ZOOM_FACTOR_MEDIUM);
      break;
    case ZOOM_FACTOR_MEDIUM:
      onZoomChange(ZOOM_FACTOR_LARGE);
      break;
    default:
    }
  }

  handleMinusClick = () => {
    const { zoom, onZoomChange } = this.props;

    switch(zoom) {
    case ZOOM_FACTOR_LARGE:
      onZoomChange(ZOOM_FACTOR_MEDIUM);
      break;
    case ZOOM_FACTOR_MEDIUM:
      onZoomChange(ZOOM_FACTOR_SMALL);
      break;
    default:
    }
  }

  render() {
    const { zoom } = this.props;

    return (
      <div className="Settings-zoom">
        <button
          type="button"
          className={classNames('Settings-button', {
            'is-disabled': zoom === ZOOM_FACTOR_LARGE,
          })}
          disabled={zoom === ZOOM_FACTOR_LARGE}
          onClick={this.handlePlusClick}
        >
          +
        </button>
        <button
          type="button"
          className={classNames('Settings-button', {
            'is-disabled': zoom === ZOOM_FACTOR_SMALL,
          })}
          disabled={zoom === ZOOM_FACTOR_SMALL}
          onClick={this.handleMinusClick}
        >
          –
        </button>
      </div>
    );
  }
}

ZoomSetting.propTypes = propTypes;

export default ZoomSetting;
