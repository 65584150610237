import React, { Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import sample from 'lodash.sample';
import { TITLE_CENTER } from './title';
import scrollElementTo from 'utilities/scroll-element-to';
import getViewportCenter from 'utilities/get-viewport-center';
import getFootnoteOffset from 'utilities/get-footnote-offset';
import getBubbleCenter from 'utilities/get-bubble-center';
import './drag-scroll.css';

const FRICTION_FACTOR = 0.95;
const ACTIVATE_AFTER_JUMP_DELAY = 750;
const TIME_TO_PAUSE = 125;
const FIRST_BUBBLE_ID = '1';

const propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  zoom: PropTypes.number.isRequired,
  onFootnoteClick: PropTypes.func.isRequired,
  activateBubble: PropTypes.func.isRequired,
};

class DragScroll extends Component {
  state = {
    pristine: true,
    dragging: false,
  }

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('mouseup', this.mouseUpHandle);
    window.addEventListener('mousemove', this.mouseMoveHandle);
    this.myRef.current.addEventListener('click', this.handleFootnoteClick);

    const element = this.myRef.current;
    element.scrollTop = 0;
    element.scrollLeft = 0;

    this.navigateToStartPosition();
  }

  navigateToStartPosition = () => {
    const { zoom } = this.props;

    scrollElementTo([
      TITLE_CENTER[0] * zoom,
      TITLE_CENTER[1] * zoom,
    ], {
      duration: 0,
      scrollToCenter: true,
    });
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.zoom === this.props.zoom) {
      return null;
    }

    const factor = this.props.zoom / prevProps.zoom
    const element = this.myRef.current;

    const viewPortCenter = getViewportCenter();

    const coords = {
      top: (element.scrollTop + viewPortCenter.top) * factor - viewPortCenter.top,
      left: (element.scrollLeft + viewPortCenter.left) * factor - viewPortCenter.left,
    };

    return coords;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      const element = this.myRef.current;
      element.scrollTop = this.scrollTop = snapshot.top;
      element.scrollLeft = this.scrollLeft = snapshot.left;

      console.log(element.scrollTop, element.scrollLeft);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.mouseUpHandle);
    window.removeEventListener('mousemove', this.mouseMoveHandle);
    this.myRef.current.removeEventListener('click', this.handleFootnoteClick);
  }

  handleFootnoteClick = (e) => {
    const { activateBubble } = this.props;
    const { target } = e;

    if (this.wasDragging) {
      this.wasDragging = false;
      return;
    }

    if (target.classList.contains('Link-circle')) {
      e.preventDefault();
      const linkElement = target.parentNode;
      const id = linkElement.getAttribute('href').slice(1);
      const offset = getFootnoteOffset(id);
      this.stopScrollToFootnote = scrollElementTo([offset.left, offset.top]);

      this.props.onFootnoteClick(id);
    } else if (target.classList.contains('Footnote-link')) {
      e.preventDefault();
      const id = target.getAttribute('href').slice(1);
      const position = getBubbleCenter(id);
      this.stopScrollToFootnote = scrollElementTo([position.x, position.y], {
        scrollToCenter: true,
      });
    } else if (target.classList.contains('TextBubble-shape')) {
      const bubble = target.parentNode;
      const isHidden = bubble.classList.contains('is-hidden');

      if (isHidden) {
        activateBubble(bubble.id);
      } else {
        const nextBubbleIds = bubble.dataset.nextBubbles.split(',');
        const nextBubble = sample(nextBubbleIds);
        this.scrollToAndActivateBubble(nextBubble);
      }
    } else {
      activateBubble(null);
    }
  }

  scrollToAndActivateBubble = (id) => {
    const { activateBubble } = this.props;

    const position = getBubbleCenter(id);
    this.stopScrollToFootnote = scrollElementTo([position.x, position.y], {
      scrollToCenter: true,
    });

    const curriedActivateBubble = () => activateBubble(id);
    setTimeout(curriedActivateBubble, ACTIVATE_AFTER_JUMP_DELAY);
  }

  mouseUpHandle = (e) => {
    if (this.ignoreMouseup) {
      this.ignoreMouseup = false;
      e.stopPropagation();
      return;
    }

    if (this.state.dragging) {
      this.setState({
        dragging: false,
      });

      // Important!
      e.stopPropagation();

      // Do not add inertia effect if last mouse move was too long ago.
      const endTime = Date.now();
      if (endTime - this.lastMouseMove > TIME_TO_PAUSE) {
        return;
      }

      const deltaTime = endTime - this.startTime;
      const deltaX = e.clientX - this.startX;
      const deltaY = e.clientY - this.startY;

      this.velocityX = deltaX / deltaTime * 16;
      this.velocityY = deltaY / deltaTime * 16;

      if (this.velocityX !== 0 || this.velocityY !== 0) {
        // Floating point scroll position
        this.scrollLeft = this.myRef.current.scrollLeft;
        this.scrollTop = this.myRef.current.scrollTop;
        window.requestAnimationFrame(this.step)
      }
    }
  }

  mouseDownHandle = (e) => {
    // Clear physics in any case.
    this.velocityX = 0;
    this.velocityY = 0;

    if (this.stopScrollToFootnote) {
      this.stopScrollToFootnote();
      this.stopScrollToFootnote = null;
    }

    if (this.state.pristine) {
      this.setState({ pristine: false });
      this.ignoreMouseup = true;
      this.scrollToAndActivateBubble(FIRST_BUBBLE_ID);
      e.preventDefault();
      return;
    }

    if (!this.state.dragging) {
      this.setState({
        dragging: true,
      })
      this.lastClientX = e.clientX;
      this.lastClientY = e.clientY;

      this.startTime = this.lastMouseMove = Date.now();
      this.startX = e.clientX;
      this.startY = e.clientY;

      // This is important!
      e.preventDefault();
    }
  }

  mouseMoveHandle = (e) => {
    if (this.state.dragging) {
      this.wasDragging = true;

      const element = this.myRef.current;

      element.scrollLeft -= (-this.lastClientX + (this.lastClientX = e.clientX));
      element.scrollTop -= (-this.lastClientY + (this.lastClientY = e.clientY));

      const nowTime = Date.now();

      // Readjust start point and time when mouse was paused.
      if (nowTime - this.lastMouseMove > TIME_TO_PAUSE) {
        this.startTime = nowTime;
        this.startX = e.clientX;
        this.startY = e.clientY;
      }

      this.lastMouseMove = nowTime;
    }
  }

  step = () => {
    const element = this.myRef.current;

    this.scrollLeft -= this.velocityX;
    this.scrollTop -= this.velocityY;

    this.velocityX *= FRICTION_FACTOR;
    this.velocityY *= FRICTION_FACTOR;

    element.scrollLeft = Math.round(this.scrollLeft);
    element.scrollTop = Math.round(this.scrollTop);

    if (Math.abs(this.velocityX) > 0.1 || Math.abs(this.velocityY) > 0.1) {
      window.requestAnimationFrame(this.step);
    }
  }

  renderChildren() {
    const { children } = this.props;

    if (Array.isArray(children)) {
      return children.map((item, index) => {
        return React.cloneElement(item, {
          key: item.key || index,
          onMouseUp: this.mouseUpHandle,
          onMouseDown: this.mouseDownHandle,
        });
      });
    } else if (typeof children === 'object') {
      return React.cloneElement(children, {
        onMouseUp: this.mouseUpHandle,
        onMouseDown: this.mouseDownHandle,
      });
    }
  }

  render() {
    let style = null;
    if (this.props.height && this.props.width) {
      style = {style: {height: this.props.height, width: this.props.width, overflow: 'auto'}};
    }

    return (
      <div
        className={classNames(this.props.className, 'DragScroll', {
          'is-dragging': this.state.dragging,
        })}
        {...style}
        onMouseUp={this.mouseUpHandle}
        onMouseMove={this.mouseMoveHandle}
        ref={this.myRef}
      >
        {
          this.props.children ?
            this.renderChildren() :
            null
        }
      </div>
    );
  }
}

DragScroll.propTypes = propTypes;

export default DragScroll;
