import React, { Component } from 'react';
import Loading from './loading';
import App from './app';

const BACKGROUND_IMAGE_PATH = '/images/background.jpg';

class AppLoader extends Component {
  state = {
    backgroundImage: null,
  }

  componentDidMount() {
    this.loadBackgroundImage();
  }

  loadBackgroundImage = () => {
    const backgroundImage = new Image();
    backgroundImage.onload = this.onBackgroundImageLoaded;
    backgroundImage.src = BACKGROUND_IMAGE_PATH;
  }

  onBackgroundImageLoaded = (e) => {
    const image = e.target;
    this.setState({
      backgroundImage: image,
    });
  }

  render() {
    const { backgroundImage } = this.state;

    if (backgroundImage) {
      return <App backgroundImagePath={backgroundImage.src}/>;
    } else {
      return <Loading/>;
    }
  }
}

export default AppLoader;
