function getBubbleCenter(id) {
  const bubble = document.getElementById(id);
  const styles = window.getComputedStyle(bubble);
  const top = parseIntFromStyle(styles.getPropertyValue('top'));
  const left = parseIntFromStyle(styles.getPropertyValue('left'));
  const width = parseIntFromStyle(styles.getPropertyValue('width'));
  const height = parseIntFromStyle(styles.getPropertyValue('height'));

  return {
    x: left + width / 2,
    y: top + height / 2,
  };
}

function parseIntFromStyle(style) {
  const numberPortion = style.match(/\d+/)[0];
  return parseInt(numberPortion, 10);
}

export default getBubbleCenter;
