import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LangSetting from './lang-setting';
import ZoomSetting from './zoom-setting';
import './settings.css';

const propTypes = {
  lang: PropTypes.string.isRequired,
  zoom: PropTypes.number.isRequired,
  onLangChange: PropTypes.func.isRequired,
  onZoomChange: PropTypes.func.isRequired,
};

class Settings extends Component {
  render() {
    const { lang, zoom, onLangChange, onZoomChange } = this.props;

    return (
      <div className="Settings">
        <LangSetting
          lang={lang}
          onLangChange={onLangChange}
        />
        <ZoomSetting
          zoom={zoom}
          onZoomChange={onZoomChange}
        />
      </div>
    );
  }
}

Settings.propTypes = propTypes;

export { ZOOM_FACTOR_SMALL, ZOOM_FACTOR_MEDIUM, ZOOM_FACTOR_LARGE,
  ZOOM_FACTOR_DEFAULT } from './zoom-setting';
export { LANG_DEFAULT } from './lang-setting';
export default Settings;
