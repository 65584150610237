import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DragScroll from './drag-scroll';
import Title from './title';
import FootnoteGroup from './footnote-group';
import Settings, { LANG_DEFAULT, ZOOM_FACTOR_DEFAULT } from './settings';
import TextBubble from './text-bubble';
import bubbles from 'data/bubbles.json';
import './app.css';

const propTypes = {
  backgroundImagePath: PropTypes.string.isRequired,
};

class App extends Component {
  state = {
    activeBubble: null,
    activeFootnote: null,
    lang: LANG_DEFAULT,
    zoom: ZOOM_FACTOR_DEFAULT,
  }

  onBubbleClick = (id) => {
    this.setState({
      activeBubble: id,
    });
  }

  onFootnoteClick = (id, bubbleId) => {
    if (bubbleId) {
      this.setState({
        activeFootnote: id,
        activeBubble: bubbleId,
      });
    } else {
      this.setState({
        activeFootnote: id,
      });
    }
  }

  handleLangChange = (newLang) => {
    this.setState({
      lang: newLang,
    });
  }

  handleZoomChange = (newZoom) => {
    this.setState({
      zoom: newZoom,
    });
  }

  render() {
    const { activeBubble, activeFootnote, lang, zoom } = this.state;
    const { backgroundImagePath } = this.props;

    return (
      <div>
        <Settings
          lang={lang}
          zoom={zoom}
          onLangChange={this.handleLangChange}
          onZoomChange={this.handleZoomChange}
        />

        <DragScroll
          className="container"
          width="100vw"
          height="100vh"
          zoom={zoom}
          onFootnoteClick={this.onFootnoteClick}
          activateBubble={this.onBubbleClick}
        >
          <div
            className={classNames('App', `App--${lang}`)}
            style={{
              fontSize: `${zoom}em`,
              backgroundImage: `url(${backgroundImagePath})`,
            }}
            onClick={this.resetBubbles}
          >

            <Title lang={lang} />

            {
              bubbles.map((item) => {
                return (
                  <TextBubble
                    key={item.id}
                    id={item.id}
                    lang={lang}
                    active={activeBubble === item.id ? true : false}
                    x={item.x}
                    y={item.y}
                    width={item.width}
                    height={item.height}
                    viewBox={item.viewBox}
                    path={item.path}
                    externalLink={item.externalLink}
                    links={item.links}
                    nextBubbles={item.nextBubbles}
                  />
                );
              })
            }

            <FootnoteGroup
              active={activeFootnote}
              lang={lang}
              onClick={this.onFootnoteClick}
            />

          </div>
        </DragScroll>
      </div>
    );
  }
}

App.propTypes = propTypes;

export default App;
