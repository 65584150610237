import React, { Component } from 'react';
import './loading.css';
import 'load-awesome/css/line-spin-clockwise-fade.css';

class Loading extends Component {
  render() {
    return (
      <div className="Loading">
        <div className="Loading-inner">
          <div className="la-line-spin-clockwise-fade la-3x">
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
          </div>
        </div>
      </div>
    );
  }
}

export default Loading;
