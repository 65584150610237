import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ExternalLink from './external-link';
import Link from './link';
import './text-bubble.css';

const FIRST_BUBBLE_ID = '1';

const propTypes = {
  active: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  x: PropTypes.string.isRequired,
  y: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  viewBox: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  links: PropTypes.array,
  externalLink: PropTypes.object,
  nextBubbles: PropTypes.array.isRequired,
};

class TextBubble extends Component {
  static defaultProps = {
    active: false,
    nextBubbles: [FIRST_BUBBLE_ID],
  }

  renderLinks() {
    const { links, lang } = this.props;

    return links ?
      links.map((link) => (
        <Link
          className="TextBubble-clickable"
          key={link.href}
          href={link.href}
          x={link.coordinates[lang].x}
          y={link.coordinates[lang].y}
        />
      )) :
      null;
  }

  renderExternalLink() {
    const { externalLink, lang } = this.props;

    return externalLink && externalLink[lang] ?
      <ExternalLink
        className="TextBubble-clickable"
        x={externalLink[lang].x}
        y={externalLink[lang].y}
        href={externalLink[lang].href}
        blank
      /> :
      null;
  }

  render() {
    const { active, id, lang, x, y, width, height, viewBox, path, nextBubbles } = this.props;

    const svgClass = classNames('TextBubble', {
      'is-hidden': !active,
    });

    const imagePath = `/bubbles/${id}_${lang}.png`;

    return (
      <svg
        id={id}
        className={svgClass}
        width={width}
        height={height}
        viewBox={viewBox}
        style={{
          position: 'absolute',
          left: x,
          top: y
        }}
        data-next-bubbles={nextBubbles}
      >
        <g>
          <clipPath id={`mask-${id}`}>
            <path d={path}/>
          </clipPath>
        </g>
        <path
          className="TextBubble-shape TextBubble-clickable"
          d={path}
        />
        <image
          className="TextBubble-image"
          clipPath={`url(#mask-${id})`}
          xlinkHref={imagePath}
          height="100%"
          width="100%"
          preserveAspectRatio="xMidYMin slice"
        />
        {
          active ? this.renderLinks() : null
        }
        {
          active ? this.renderExternalLink() : null
        }
      </svg>
    );
  }
}

TextBubble.propTypes = propTypes;

export default TextBubble;
